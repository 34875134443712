<template>
  <div class="faq_tabgroup">
    <section class="faq_tab">
      <div class="tab_menu">
        <button
          v-for="(btnName, btnIdx) in tabBtns"
          :key="btnIdx"
          type="button"
          class="tab_btn"
          :class="1000 + btnIdx + 1 === btnId && 'now'"
          @click="handleTabBtn(btnIdx)"
        >
          {{ btnName }}
        </button>
      </div>
      <div class="tab_wrap">
        <ol class="tab_list">
          <li
            v-for="(faqItem, faqIdx) in faqList"
            :key="faqItem.Idx"
            class="tab_desc"
            :class="detailId[faqIdx] === true && 'show'"
          >
            <button type="button" class="question" @click="handleAnswer(faqIdx)">{{ faqItem.Title }}</button>
            <div class="faq_conts">
              <div class="answer">
                <p v-html="faqItem.Contents" />
              </div>
            </div>
          </li>
        </ol>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "FaqTabGroup",
  props: {
    faqList: {
      type: Array,
    },
    btnId: {
      type: Number,
    },
    detailId: {
      type: Array,
    },
    tabBtns: {
      type: Array,
    },
    handleBtnId: {
      type: Function,
    },
    handleDetailId: {
      type: Function,
    },
  },
  methods: {
    handleTabBtn(num) {
      this.$emit("handleBtnId", 1000 + num + 1)
    },
    handleAnswer(idx) {
      this.$emit("handleDetailId", idx)
    },
  },
}
</script>
