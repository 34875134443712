<template>
  <div id="faq" class="sub_wrap">
    <section class="sub_sec">
      <SubPageExplain>
        <template>
          <strong class="title">휴대폰분실보호 서비스를<br />100% 활용하는 방법? 확인해 보세요.</strong>
        </template>
        <template v-solt:desc>
          <p>
            FAQ 목록 외에 더 궁금한 내용이 있으시다면 홈 > 고객센터 (1:1 문의)를 통해 문의사항을 접수해 주세요. 담당자
            확인 후 즉시 답변해 드리겠습니다.
          </p>
        </template>
      </SubPageExplain>
      <FaqTabGroup
        :faqList="faqList"
        :btnId="btnId"
        :detailId="detailId"
        :tabBtns="tabBtns"
        @handleBtnId="handleBtnId"
        @handleDetailId="handleDetailId"
      />
      <template v-if="more">
        <SubmitButton :btnObj="{ class: 'more', text: '더보기' }" @btnEvt="showMore" />
      </template>
    </section>
  </div>
</template>

<script>
import SubPageExplain from "@/components/SubPageExplain.vue"
import SubmitButton from "@/components/SubmitButton.vue"
import FaqTabGroup from "@/components/Faq/FaqTabGroup.vue"

export default {
  name: "Faq",
  components: {
    SubPageExplain,
    SubmitButton,
    FaqTabGroup,
  },
  data() {
    return {
      faqList: [],
      btnId: null,
      detailId: [],
      currentPage: 0,
      more: false,
      tabBtns: ["공통", "APP 이용", "휴대폰 찾기 웹", "기타"],
    }
  },
  mounted() {
    this.handleBtnId(1001)
  },
  methods: {
    handleBtnId(num) {
      this.detailId = []
      this.currentPage = 0
      this.btnId = num
      this.setHttpData()
    },
    handleDetailId(idx) {
      this.detailId[idx] = !this.detailId[idx]
      this.detailId.push()
    },
    showMore() {
      this.currentPage++
      this.setHttpData()
    },
    setHttpData() {
      const httpData = {}
      httpData.Header = {
        CmdType: "010000",
        CallApp: "www",
        TransactionID: this.$guid(),
      }
      httpData.Body = {
        Group: this.btnId,
        Length: 6,
        Offset: this.currentPage * 5,
      }

      this.fetchFaqList(httpData)
    },
    async fetchFaqList(httpData) {
      try {
        const { data } = await this.$SRSServer(httpData)
        const sliceData = data.Body.splice(5, 1)

        if (sliceData.length === 0) this.more = false
        else this.more = true
        if (this.currentPage !== 0) {
          const concatArr = this.faqList.concat(data.Body)
          this.faqList = concatArr
        } else {
          this.faqList = data.Body
        }
      } catch (error) {
        console.log(error)
      }
    },
  },
}
</script>
